<template>
  <div class="NotificationsMSTeams">
    <div class="top">
      <div class="title">
        Microsoft Teams Notifications
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <m-s-teams :monitor="monitor"
                                :general="general"
                                @update="$emit('update')" />
      </div>
    </div>

  </div>
</template>

<script>
import MSTeams from '@/components/Monitor/Notifications/MSTeamsWebhook/MSTeams.vue'
export default {
  components: { MSTeams },

  metaInfo: {
    title: 'MS Teams Notifications'
  },

  props: {
    monitor: {
      required: false,
      type: Object
    },
    general: {
      required: false,
      default: false,
      type: Boolean
    }
  }

}
</script>

<style lang="scss" scoped>
.NotificationsMSTeams {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .title {
      font-size: 24px;
      font-weight: 400;
      line-height: 1.5;
      color: #444;
    }
  }
}
</style>
