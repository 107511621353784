export default {
  generalStoreWebhook (webhook) {
    return axios.post(`/monitors/general/notifications/ms-teams-webhook`, {
      webhook_url: webhook
    })
  },

  storeWebhook (monitorId, webhook) {
    return axios.post(`/monitors/${monitorId}/notifications/ms-teams-webhook`, {
      webhook_url: webhook
    })
  }
}
