const baseURL = 'notification-channels'

export default {
  /**
   * Delete notification channel.
   *
   * @param id
   * @returns Promise
   */
  delete (id) {
    return axios.delete(`${baseURL}/${id}`).then(response => response.data)
  },

  generalDelete (id) {
    return axios.delete(`${baseURL}/general/${id}`).then(response => response.data)
  }
}
